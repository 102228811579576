<template>
  <div>
    <div class="exp-checkbox">
      <label class="exp-checkbox-label checkbox checkbox-lg checkbox-outline checkbox-success">
        <input
          type="checkbox"
          name=""
          v-model="mutated_value"
          @click="checkbox_clicked"
        />
        <span class="exp-checkbox-inner-span"></span>
      </label>
    </div>
    <div v-if="text" class="exp-checkbox-text">{{ text }}</div>
  </div>
</template>

<style lang="css" scoped>

.checkbox > span {
  border-radius: 0.22rem;
}

.checkbox.checkbox-outline.checkbox-lg > span:after {
  width: 8px;
  height: 13px;
  margin-left: 4.5px;
  margin-top: 0px;
}

.checkbox > span:after {
  border-width: 0 4px 4px 0 /*rtl:ignore*/ !important;
}

.checkbox.checkbox-outline.checkbox-lg > span {
  height: 22px;
  width: 22px;
}


.checkbox.checkbox-success > span {
  border: 3px solid #0085eb !important;
}

.checkbox.checkbox-outline.checkbox-success > input:checked ~ span {
  background-color: transparent;
  border-color: #0085eb;
}

.checkbox.checkbox-outline.checkbox-success > input:checked ~ span:after {
  border-color: #0085eb;
}

.exp-checkbox {
  margin-left: 4px;
}

.exp-checkbox-inner-span {
  white-space: nowrap;
  overflow: hidden;
  margin: 0 !important;
  display: inline-block;
}

.exp-checkbox-label {
  white-space: nowrap;
  overflow: hidden;
  margin: 0 !important;
  display: inline-block;
}

.exp-checkbox-text {
  float: left;
  margin-left: 8px;
  margin-top: 6px;
  font-weight: bold;
}

.exp-checkbox {
  float: left;
}

</style>


<script>
export default {
  name: 'table-checkbox',
  data() {
    return {
      mutated_value: null
    };
  },
  props: ['value','row','column','name','text','item'],
  emits: ['value_changed'],
  async mounted() {
    this.mutated_value = this.value;
  },

  watch: {
    value() {
      this.mutated_value = this.value;
    }
  },

  methods: {
    checkbox_clicked() {


      this.$emit('value_changed', {
        value:    !this.mutated_value,
        row:      this.row,
        column:   this.column,
        name:     this.name,
        item:     this.item
      });
    },
  },
  computed: {


    rows() {
      // we need to take the items property and add all child "items" to this rows array

      const rows = [];

      this.items.forEach((item, index) => {
        rows.push(item);

        if (item.items) {
          item._expanded = false;
          item.items.forEach((child_item, child_index) => {
            rows.push(child_item);

            // check child items and add as well
            if (child_item.items) {
              child_item._expanded = false;
              child_item.items.forEach((child_child_item, child_child_index) => {
                rows.push(child_child_item);

                if (child_child_item.items) {
                  child_child_item._expanded = false;
                  child_child_item.items.forEach((child_child_child_item, child_child_child_index) => {
                    rows.push(child_child_child_item);
                  });
                }
              });
            }
          });
        }
      });

      return rows;
    },
  }
};
</script>

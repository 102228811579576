/**
 * Main startup and init for the user
 * 
 * 1. Check that user is logged in
 * 2. Update the company list
 * 3. Update the period list
 * 4. Make sure the currentCompanyId is set appropriately
 * 5. Make sure the currentPeriodId is set appropriately
 * 6. Make sure the access list is set
 * 
 */

import {
  SET_COMPANY_ID,
  SET_SID,
  SET_PERIOD_ID,
  SET_PERIODS,
  SET_TITLES,
  SET_TERMS,
  SET_ACCEPTED_TERMS,
  SET_SETTINGS,
  SET_COMPANIES,
  SET_USER_ID,
  SET_VERSION,
  SET_INSTANCE_ID,
  SET_PROFILE,
  SET_USER,
  SET_CUSTOMER
} from '@/core/services/store/common.module';

import {
  SET_ACCESS,
  SET_IS_THS,
  SET_IS_SUPERADMIN,
  LOGOUT,
  SET_INIT_COMPLETE,
  SET_ACCESS_HIERARCHY,
  SET_PAGES,
  SET_UNLOCKS
} from '@/core/services/store/auth.module';
import axios from 'axios';
import store from '@/core/services/store';
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from '@/core/services/store/htmlclass.module.js';
import Loader from '@/view/content/Loader.vue';
import { mapGetters } from 'vuex';

/**
 * Is called once from Layout.vue
 * 
 * callback => (successAuthenticate: boolean, successInit: boolean)
 */
export async function initUserMemlist(callback) {
  await startupMemlistAuthenticatedUser(callback);
}

/**
 * Must be called on every reload!
 */
async function startupMemlistAuthenticatedUser(callback) {
  if (!store.getters.isAuthenticated) {
    store.dispatch(REMOVE_BODY_CLASSNAME, 'page-loading');
    callback(false, false);
    return;
  }

  let currentCompanyId = store.getters.currentCompanyId;

  // get a list of all companies we have access to
  const resInit = await axios.get(`/user/init/${currentCompanyId}`);

  if (resInit.status !== 200) {
    console.error('init: bad status');
    // some old stuff, not sure if needed?
    store.dispatch(REMOVE_BODY_CLASSNAME, 'page-loading');
    callback(true, false);
    return;
  }

  const data = resInit.data;

  
  if (!currentCompanyId) {
    currentCompanyId = data.selected_company_id;
    // set the company if no company was selected
    store.dispatch(SET_COMPANY_ID, currentCompanyId);
  }

  
  // set the version of memlist so QA knows whats been released
  store.dispatch(SET_VERSION, data.version);
  // set which instance is being used
  store.dispatch(SET_INSTANCE_ID, data.instance_id);
  // some settings where we can see whats been enabled in the system
  store.dispatch(SET_SETTINGS, data.settings);
  // all pages available in the system, not just for the user
  store.dispatch(SET_PAGES, data.pages);
  store.dispatch(SET_UNLOCKS, data.unlocks);

  // profile, which will be used for advanced search results
  store.dispatch(SET_PROFILE, data.profile);

  // user, my user data
  store.dispatch(SET_USER, data.user);
  
  // the current period selected which should only show data for this period
  let currentPeriodId = store.getters.currentPeriodId;

  
  if (!currentPeriodId || currentPeriodId === -1) {
    const activePeriod = data.periods.find(item => item.active);
    // if no period was selected, select the active one
    store.dispatch(SET_PERIOD_ID, activePeriod.id);
    currentPeriodId = activePeriod.id;
  }

  
  if (!data.user) {
    console.error('init: user is null');
    return;
  }


  // set the user id
  store.dispatch(SET_USER_ID, data.user.user_id);
  // is the user a THS admin
  store.dispatch(SET_IS_THS, data.user.is_ths);
  // is the user a superadmin (deprecated)
  store.dispatch(SET_IS_SUPERADMIN, data.user.is_admin);
  // set accepted terms
  store.dispatch(SET_ACCEPTED_TERMS, data.user.accepted_terms);

  // Set the customer <- very important, this decides the context
  store.dispatch(SET_CUSTOMER, data.customer);
  

  // which companies this user has access to
  store.dispatch(SET_COMPANIES, data.companies);
  // all periods, will be hidden if we only have 1 period (infinite periods)
  store.dispatch(SET_PERIODS, data.periods);
  // the top company id
  store.dispatch(SET_SID, data.sid);
  // init lits of all terms
  await updateTerms();
  // let everyone know initialization is complete
  store.dispatch(SET_INIT_COMPLETE, { complete: true, initPeriodId: currentPeriodId });

  store.dispatch(SET_ACCESS_HIERARCHY, data.access);

  const root_access = data.access.children[0];

  const fast_acl = {};

  for (const a of root_access.pages) {
    fast_acl[a.page.name] = a.page;
  }

  store.dispatch(SET_ACCESS, fast_acl);

  callback(true, true);
}

/**
 * 
 * Change the current company id
 * 
 * This should cause the currently loaded page to reload data for the current company.
 * 
 */
export async function setCurrentCompanyId(company_id) {
  const company = store.getters.companies.find(item => item.company_id === company_id);

  if (!company.company_id) {
    console.error('invalid company, not found in ', store.getters.companies);
    return false;
  }

  // when changing company, we need to get an updated access list
  if (company_id !== store.getters.currentCompanyId) {
    const resInit = await axios.get(`/user/init/${company_id}`);

    if (resInit.status !== 200) {
      console.error('init2: bad status');
      return;
    }

    const data = resInit.data;

    store.dispatch(SET_ACCESS_HIERARCHY, data.access);

    const root_access = data.access.children[0];

    const fast_acl = {};

    for (const a of root_access.pages) {
      fast_acl[a.page.name] = a.page;
    }

    store.dispatch(SET_ACCESS, fast_acl);
  }

  store.dispatch(SET_COMPANY_ID, company.company_id);
  return true;
}


export async function updateTerms() {

  const company_id = store.getters.sid;
  const res = await axios.get(`/company/allterms/${company_id}`)

  if (res.status !== 200) {
    console.error('updateTerms: bad status');
    return;
  }
 
  const terms = res.data;
  store.dispatch(SET_TERMS, terms);
}


export async function updateAcceptedTerms() {

  const res = await axios.get(`/company_term_user`)

  if (res.status !== 200) {
    console.error('updateAcceptedTerms: bad status');
    return;
  }
 
  const accepted_terms = res.data;
  store.dispatch(SET_ACCEPTED_TERMS, accepted_terms);
}

<template>
  <div>
    <b-modal ref="showModal" size="lg" hide-footer>

      <div class="mt-4">
        <DualListBox
          :source="sourceRegions"
          :destination="destRegions"
          label="name"
          @onChangeList="onChangeRegion"
        />
      </div>

      <div class="mt-4">
        <DualListBox
          :source="sourceCommunes"
          :destination="destCommunes"
          label="name"
          @onChangeList="onChangeCommune"
        />
      </div>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>

:deep .btn-action {
  border: 0;
  background: none;
  outline: none !important;
  box-shadow: none;
  outline: none !important;
  padding: 0.5rem 1rem;
  color: #a2a5b9;
  font-weight: 500;
  font-size: 0.9rem;
  background-color: #c1c1c1;
  transition: all 0.3s ease;
  border-radius: 4px;
}

:deep .select-all {
  border: 0;
  background: none;
  outline: none !important;
  box-shadow: none;
  outline: none !important;
  padding: 0.5rem 1rem;
  color: #a2a5b9;
  font-weight: 500;
  font-size: 0.9rem;
  background-color: #c1c1c1;
  transition: all 0.3s ease;
  border-radius: 4px;
  cursor: pointer;
}

:deep .deselect-all {
  border: 0;
  background: none;
  outline: none !important;
  box-shadow: none;
  outline: none !important;
  padding: 0.5rem 1rem;
  color: #a2a5b9;
  font-weight: 500;
  font-size: 0.9rem;
  background-color: #c1c1c1;
  transition: all 0.3s ease;
  border-radius: 4px;
  cursor: pointer;
}

:deep .list-box-wrapper {
  font-family: Poppins, Helvetica, 'sans-serif';
}


@media (min-width: 992px) {
  .modal-lg {
    max-width: auto !important;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: auto !important;
  }
}
</style>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import axios from 'axios';
import { mapGetters } from 'vuex';
import DualListBox from 'dual-listbox-vue';
import 'dual-listbox-vue/dist/dual-listbox.css';


export default {
  name: 'area-allocation-modal',
  props: [],
  emits: ['area_alloc_changed'],
  mixins: [toasts],
  components: {
    DualListBox
  },
  computed: {
    ...mapGetters(['currentCompanyId'])
  },
  async mounted() {

  },
  data() {
    return {
      company_id: null,
      destCommuneList: {},
      communeList: {},
      map_by_region: false,
      sourceRegions: [],
      destRegions: [],
      sourceCommunes: [],
      destCommunes: [],
      initialized: false,
      regions: {}
    };
  },
  watch: {

  },
  methods: {
    show() {
      this.$refs['showModal'].show();
    },
    hide() {
      this.$refs['showModal'].hide();
    },

    async setup() {

      this.destCommuneList = {};
      this.communeList = {};
      this.map_by_region = false;
      this.sourceRegions = [];
      this.destRegions = [];
      this.sourceCommunes = [];
      this.destCommunes = [];
      this.initialized = false;
      this.regions = {};

      await this.load_regions();

      for (var k in this.regions) {

        this.sourceRegions.push({ code: k, name: this.regions[k].region });

        var communes = this.regions[k].communes;

        for (var c in communes) {
          this.communeList[c] = { rc: k, cc: c };
        }
      }

      this.sourceRegions.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });


      this.onChangeRegion({ source: this.sourceRegions, destination: this.destRegions });

    },

    async load_regions() {

      try {
        const res = await axios.get('https://zip.memlist.se/api/v1/regions_communes');

        this.regions = res.data.regions;
        this.regionOptions = [];

        for (var k in this.regions) {
          this.regionOptions.push({ code: k, label: this.regions[k].region });
        }

        this.regionOptions.sort(function(a, b) {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SEARCH.UNABLE_LIST_REGIONS'));
      }
    },

    async load_company(company_id) {
      this.company_id = company_id;
      await this.setup();
      await this.load_area_alloc(company_id);
    },

    /// -------------------- legacy methods ------------------------- ///
    async load_area_alloc(company_id) {

      try {
        const res = await axios.get(`/area_alloc?company_id=${company_id}`);

        if (res.status !== 200) {
          return null;
        }

        var aa = res.data.area_alloc;

        this.destRegions = [];

        var tmp = {};

        for (var a of aa) {

          if (!(a.rc in tmp)) {
            this.sourceRegions = this.sourceRegions.filter(item => item.code !== a.rc)
            var obj = { code: a.rc, name: this.regions[a.rc].region };

            this.destRegions.push(obj);
            tmp[a.rc] = true;
          }
        }

        this.onChangeRegion({ source: this.sourceRegions, destination: this.destRegions });

        tmp = {};

        this.destCommunes = [];

        for (var a of aa) {

          if (!(a.cc in tmp)) {
            this.sourceCommunes = this.sourceCommunes.filter(item => (item.code + '') !== (a.cc + ''))

            if (this.regions[a.rc].communes === undefined || this.regions[a.rc].communes[a.cc] === undefined) {
              continue;
            }

            var obj = { code: a.cc, name: this.regions[a.rc].communes[a.cc], region_code: a.rc };

            this.destCommunes.push(obj);
            tmp[a.cc] = true;
          }
        }

        this.initialized = true;

      }
      catch (err) {

      }

    },

    async post_communes() {
      var regions = [];

      for (var k of this.destRegions) {
        regions.push(k.code);
      }

      var communes = [];

      for (var k in this.destCommuneList) {
        communes.push(this.destCommuneList[k]);
      }

      const res = await axios.post('/area_alloc', { company_id: this.company_id, communes: communes, regions: regions })

      if (res.status !== 201) {
        console.error('unable to post area_alloc');
        return null;
      }

      this.$emit('area_alloc_changed', this.company_id, res.data);

    },
    onChangeRegion: function ({ source, destination }, post) {

      this.sourceRegions = source;
      this.destRegions = destination;

      this.sourceCommunes = [];

      for (var i = 0; i < this.destRegions.length; ++i) {
        // populate communes based on selected regions
        var region = this.regions[this.destRegions[i].code];
        for (var k in region.communes) {
          this.sourceCommunes.push({
            code: k,
            name: region.communes[k],
            region_code: region.region_code
          });
        }
      }

      // remove from source commune already selected dest communes
      for (var i = 0; i < this.destCommunes.length; ++i) {
        for (var j = 0; j < this.sourceCommunes.length; ++j) {
          if (this.destCommunes[i].code === this.sourceCommunes[j].code) {
            this.sourceCommunes.splice(j, 1);
            break;
          }
        }
      }

      this.sourceCommunes.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      this.destCommunes.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      if (this.initialized) {
        this.post_communes();
      }
    },
    onChangeCommune: function ({ source, destination }) {
      this.sourceCommunes = source;
      this.destCommunes = destination;

      this.destCommuneList = {};

      for (var rec of this.destCommunes) {
        var commune = this.communeList[rec.code];
        this.destCommuneList[commune.cc] = { rc: commune.rc, cc: commune.cc };
      }

      this.post_communes();
    },


  }
};
</script>
